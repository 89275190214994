import React, { FunctionComponent, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  clearAuthUser,
  clearIsTalentOnboarded,
  clearProfilePicture,
  clearTalentProfile,
  clearImpersonator,
  clearSettings,
  clearLastVerifyEmailResendTime,
  clearLastForgotPassEmailResendTime,
  clearActivities,
} from '../../../store/user.slice';
import { useAuthService } from '../../../service/auth.service';
import { Page } from '../../../framework';
import { setActivityFlyoutOpen } from '../../../store/app.slice';

const LogoutPage: FunctionComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const authService = useAuthService();
  const { userType } = useParams<{ userType: 'talent' | 'hirer' }>();

  useEffect(() => {
    (async () => {
      await authService.logout();
      dispatch(clearTalentProfile());
      dispatch(clearAuthUser());
      dispatch(clearIsTalentOnboarded());
      dispatch(clearProfilePicture());
      dispatch(clearImpersonator());
      dispatch(clearSettings());
      dispatch(clearLastVerifyEmailResendTime());
      dispatch(clearLastForgotPassEmailResendTime());
      dispatch(clearActivities());

      dispatch(setActivityFlyoutOpen({ isOpen: false }));

      history.replace(`/auth/${userType}/login`);
    })();
  }, []);

  return <Page className='page-login' />;
};

export default LogoutPage;
