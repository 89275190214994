import React, { FunctionComponent, useEffect } from 'react';
import { Button, Form, Input, Typography } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet-async';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Page, Div } from '../../../framework';
import { RootState } from '../../../store/store';
import Menu from '../../../component/Menu/Menu';
import { passwordRules } from '../../../helper/constants';
import PasswordHint from '../../../component/PasswordHint/PasswordHint';
import RequestError from '../../../component/RequestError/RequestError';
import { useAuthService } from '../../../service/auth.service';
import { AUTH_ROUTES } from 'client/src/route/constant';

import './ResetPassword.scss';
import { useSelector } from 'react-redux';

const { Title, Paragraph, Text } = Typography;

const ResetPasswordPage: FunctionComponent = () => {
  const history = useHistory();
  const authService = useAuthService();
  const search = useLocation().search;
  const [form] = Form.useForm();
  const code = new URLSearchParams(search).get('code') as string;
  const userId = new URLSearchParams(search).get('userId') as string;
  const { userType } = useParams<{ userType: 'talent' | 'hirer' }>();
  const { error } = useSelector((state: RootState) => state.app);

  const hasCodeInvalidError =
    error['auth/completeResetPassword']?.code === 'CODE_INVALID';
  const hasLimitExceededError =
    error['auth/completeResetPassword']?.code === 'LIMIT_EXCEEDED_EXCEPTION';
  const hasInvalidPasswordError =
    error['auth/completeResetPassword']?.code === 'INVALID_PASSWORD';

  useEffect(() => {
    if (!code || !userId) {
      history.push(AUTH_ROUTES.TALENT_LOGIN);
    }
  }, []);

  const onFinish = async (values: any) => {
    const { password } = values;

    await authService.resetPassword({ password, code, userId }, userType);
    history.push(AUTH_ROUTES.TALENT_LOGIN);
  };

  return (
    <Page className='page-reset-password'>
      <Helmet>
        <title>Reset Password</title>
        <meta name='description' content='Auth page for react starter' />
      </Helmet>
      <Div className='mobile-view'>
        <Menu />
        <Form
          name='reset-password'
          form={form}
          onValuesChange={(changedValues, values) => {
            console.log(values);
          }}
          style={{ width: '340px' }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Title level={2} style={{ marginBottom: 0 }}>
            Reset Your Password
          </Title>
          <Paragraph>
            Enter your new password to complete the password reset process
          </Paragraph>
          <Form.Item
            name='password'
            hasFeedback
            validateFirst
            rules={passwordRules}
          >
            <Input
              prefix={<LockOutlined />}
              type='password'
              placeholder='New Password'
            />
          </Form.Item>
          <PasswordHint />
          <Form.Item
            name='confirmPassword'
            hasFeedback
            rules={[
              { required: true, message: 'Please confirm your password!' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      'The two passwords that you entered do not match!'
                    )
                  );
                },
              }),
            ]}
            {...(hasInvalidPasswordError
              ? {
                  validateStatus: 'error',
                  help: error['auth/register']?.message,
                }
              : {})}
          >
            <Input
              prefix={<LockOutlined />}
              type='password'
              placeholder='Confirm New Password'
            />
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType='submit' block>
              Reset Password
            </Button>
          </Form.Item>
          <RequestError requestKey='auth/completeResetPassword' />
          {hasCodeInvalidError && (
            <Div pt='s'>
              <Text type='danger'>
                Hey, it seems like you are not using the most recent reset
                password email to reset your password. Please try again using
                the latest reset password email.
              </Text>
            </Div>
          )}
          {hasLimitExceededError && (
            <Div pt='s'>
              <Text type='danger'>
                Password Reset Limit Reached.
                <br />
                Oops! It seems like you&apos;ve tried to reset your password
                several times. For your security, we&apos;ve temporarily
                disabled password resets, please try again after some time.
              </Text>
            </Div>
          )}
        </Form>
      </Div>
    </Page>
  );
};

export default ResetPasswordPage;
