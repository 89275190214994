import React, { FunctionComponent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Input, Typography, Alert, message } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet-async';
import { Link, useParams } from 'react-router-dom';
import { Div, Page } from '../../../framework';
import Menu from '../../../component/Menu/Menu';
import { RootState } from '../../../store/store';
import {
  setLastVerifyEmailResendTime,
  setLastForgotPassEmailResendTime,
} from '../../../store/user.slice';
import { useAuthService } from '../../../service/auth.service';
import { formFieldToLowerCase } from '../../../helper/form';
import { AUTH_ROUTES } from 'client/src/route/constant';
import RequestError from '../../../component/RequestError/RequestError';
import { getEmailProviderUrl } from '../../../helper/getEmailProviderUrl';
import * as analyticService from '../../../service/analytic.service';

import './ForgotPassword.scss';

const { Title, Paragraph, Link: AntdLink } = Typography;

const ForgotPasswordPage: FunctionComponent = () => {
  const authService = useAuthService();
  const dispatch = useDispatch();
  const { lastVerifyEmailResendTime, lastForgotPassEmailResendTime } =
    useSelector((state: RootState) => state.user);
  const { userType } = useParams<{ userType: 'talent' | 'hirer' }>();
  const [successType, setSuccesType] = useState<'OK' | 'EMAIL_UNVERIFIED'>();
  const [email, setEmail] = useState<string>();
  const [allowResendEmail, setAllowResendEmail] = useState<boolean>(true);
  const [form] = Form.useForm();

  const handleRedirectToVerifyEmail = () => {
    analyticService.trackEvent('redirect to verification email', {
      src: 'forgot-password',
    });
  };

  const handleResendVerifyEmail = async () => {
    const minutes = (m: number) => m * 60 * 1000;
    const allowResend =
      !lastVerifyEmailResendTime ||
      new Date().getTime() - new Date(lastVerifyEmailResendTime).getTime() >
        minutes(1);

    if (allowResend) {
      await authService.resendVerificationEmail(email);
      dispatch(
        setLastVerifyEmailResendTime({
          lastVerifyEmailResendTime: new Date().toISOString(),
        })
      );

      setAllowResendEmail(false);
      setTimeout(() => setAllowResendEmail(true), minutes(1));
    } else {
      message.warn(
        `We've recently just sent you a verification email to ${email}. If you still haven't receive the email. please try again 1 minute later.`,
        10
      );
    }
  };

  const onFinish = async (values: any) => {
    const minutes = (m: number) => m * 60 * 1000;
    const allowResend =
      !lastForgotPassEmailResendTime ||
      new Date().getTime() - new Date(lastForgotPassEmailResendTime).getTime() >
        minutes(1);

    if (allowResend) {
      const { code } = await authService.forgotPassword(values, userType);
      setSuccesType(code);
      setEmail(values.email);
      form.resetFields();
      dispatch(
        setLastForgotPassEmailResendTime({
          lastForgotPassEmailResendTime: new Date().toISOString(),
        })
      );
    } else {
      message.warn(
        `We've recently just sent you a reset password email to ${email}. If you still haven't receive the email. please try again 1 minute later.`,
        10
      );
    }
  };

  const emailProviderUrl =
    email && getEmailProviderUrl(email, 'Reset password');

  return (
    <Page className='page-forgot-password'>
      <Helmet>
        <title>Forgot Password</title>
        <meta name='description' content='Auth page for react starter' />
      </Helmet>
      <Div className='mobile-view'>
        <Menu />
        <Form
          initialValues={{ remember: true }}
          onFinish={onFinish}
          form={form}
        >
          <Title level={3}>Forgot your password?</Title>
          <Div mb='l'>
            {successType === 'EMAIL_UNVERIFIED' ? (
              <Alert
                message={
                  <>
                    You have not{' '}
                    {emailProviderUrl ? (
                      <AntdLink
                        href={emailProviderUrl}
                        target='_blank'
                        onClick={handleRedirectToVerifyEmail}
                      >
                        verify your email address
                      </AntdLink>
                    ) : (
                      'verify your email address'
                    )}{' '}
                    yet. You could reset your password after you&apos;ve verify
                    your email address. Please click on the verification link we
                    have sent to <AntdLink>{email}</AntdLink>. If you do not see
                    the email, be sure to check your spam folder. Not received?{' '}
                    <AntdLink
                      onClick={handleResendVerifyEmail}
                      disabled={!allowResendEmail}
                    >
                      Resend verification email
                    </AntdLink>
                  </>
                }
                type='warning'
              />
            ) : null}
            {successType === 'OK' ? (
              <Alert
                message='You would receive an email immediately if you have an account with us. Click on the provided link in the mail to reset your password'
                type='success'
              />
            ) : null}
          </Div>
          <Paragraph>
            Don&apos;t worry! Resetting your password is easy. Just type in the
            email you registered with us and hit &lsquo;Send&lsquo;.
          </Paragraph>
          <Form.Item
            name='email'
            rules={[
              {
                required: true,
                message: 'Please input your email!',
              },
              {
                type: 'email',
                message: 'This is not a valid email',
              },
            ]}
          >
            <Input
              prefix={<MailOutlined />}
              placeholder='Email'
              type='email'
              onChange={() => formFieldToLowerCase(form, 'email')}
            />
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType='submit' block>
              Send
            </Button>
            <RequestError requestKey='auth/forgotPassword' />
            <Div mv='m'>
              Did you remember your password?{' '}
              <Link to={AUTH_ROUTES.TALENT_LOGIN}>Try logging in</Link>
            </Div>
          </Form.Item>
        </Form>
      </Div>
    </Page>
  );
};

export default ForgotPasswordPage;
